import React from 'react';

import Layout from '../../../Layout';
import Product from '../../shared/Product';
import { Solutions } from '../../../Icons/Values/solutions';
import Posts from '../../../Posts';

import Cover from '../../../../../static/solutions/consultoria.png';

function ProjectAndConsultancy() {
  return (
    <Layout>
      <Product.Main
        cover={Cover}
        heading="Projetos de escopo controlado e consultorias personalizadas"
        text={
          <>
            Contrate{' '}
            <span className="emphasis">profissionais de alta performance</span>{' '}
            para atender as necessidades específicas de desenvolvimento ou
            capacitação no seu negócio.
          </>
        }
        newTab={false}
        link="#contato"
      />

      <Product.TextSection
        heading="Projetos tecnológicos e consultorias técnicas com prazos e entregas específicas"
        texts={[
          'Conte com especialistas de alta performance e toda a metodologia Exactaworks de excelência operacional para projetos de tecnologia com escopo de entregas e prazos definidos.',
          'Nosso time de especialistas multidisciplinares está pronto para atender as necessidades específicas nas diferentes fases de desenvolvimento ou para promover capacitações técnicas personalizadas com times internos, lideranças, C-levels e/ou mentorias individuais com profissionais.'
        ]}
      />

      <Product.List
        heading={
          <>
            Como a Exactaworks pode{' '}
            <span className="emphasis color--green">acelerar</span> os projetos
            da <span>sua empresa</span>?
          </>
        }
        text="Combinando talentos humanos, inteligência artificial, métricas de eficiência e metodologias ágeis, nossas equipes estão preparadas para atender múltiplas demandas de tecnologia com soluções sob medida, com prazo e entregáveis pré-estabelecidos. Confira algumas possibilidades de projetos de escopo controlado ou consultorias técnicas:"
        items={[
          {
            heading: 'Product Discovery',
            text: 'Tenha especialistas e ferramentas avançadas para apoiar sua equipe na ideação, amadurecimento, planejamento e criação de produtos digitais. Atuação em Design Thinking, Lean Inception e Design Sprint.'
          },
          {
            heading: 'Business Agility e Otimização de Jira',
            text: 'Contrate profissionais especializados para promover a implementação de metodologias ágeis, arquiteturas inovadoras e otimizar a gestão de produtividade, com o uso estratégico do Jira e outras ferramentas de alta performance.'
          },
          {
            heading: 'Gestão institucional e liderança de times tech',
            text: 'Conte com apoio da Exactaworks para estabelecer processos táticos e estratégicos, com foco na implementação de um Design Organizacional autogerido a partir do O2 e criação de uma cultura Data Driven, focada no acompanhamento de métricas de eficiência, como OKRs e KPIs.'
          },

          {
            heading: 'IA para desenvolvimento tecnológico',
            text: 'Consultoria para capacitação profissional e integração de ferramentas efetivas de Inteligência Artificial para acelerar times de tecnologia. '
          },
          {
            heading: 'IA para lideranças',
            text: 'Consultoria para implementar a cultura de IA em todos os processos e pilares institucionais a partir do treinamento e capacitação técnica de lideranças.'
          }
        ]}
      />

      <Product.EmphasisText
        text={
          <>
            Alcance a <span className="emphasis">excelência</span> em seus
            projetos com a contratação de especialistas e consultorias
            personalizadas para atender às suas{' '}
            <span className="emphasis">necessidades específicas</span>.
          </>
        }
      />

      <Product.TriggerAction
        text="Conte com a expertise da Exactaworks para acelerar seus projetos e potencializar os resultados da sua empresa."
        action={{
          label: 'Fale com a gente',
          href: '#contato'
        }}
      />

      <Product.Benefits
        heading={
          <>
            Por que contratar nossos profissionais para{' '}
            <span className="color--green emphasis">
              projetos de escopo controlado e consultorias
            </span>
            ?
          </>
        }
        text="Conheça as vantagens de desenvolver pessoas e projetos com especialistas altamente capacitados e acelerados por inteligência artificial:"
        items={[
          {
            icon: <Solutions />,
            heading: 'Frameworks exclusivos e de alta performance',
            text: 'Metodologia própria estruturada e testada, integrada ao uso de ferramentas de gestão de produtividade e melhores softwares do mercado.'
          },
          {
            icon: <Solutions />,
            heading: 'Gestão de times realizada pela Exactaworks',
            text: 'Liderança Tech fundamentada na O2, garantindo máxima produtividade, engajamento, baixo turnover e realocação de profissionais rápida e segura.'
          },
          {
            icon: <Solutions />,
            heading: 'Serviço ajustável e sob demanda',
            text: 'Atendimento 100% consultivo, transparente, com comunicação direta, personalizado e focado na satisfação da empresa atendida.'
          },
          {
            icon: <Solutions />,
            heading:
              'Maior adaptabilidade para inovação e transformação digital',
            text: 'Profissionais multidisciplinares certificados em Inteligência Artificial, com desenvolvimento de soluções e tomada de decisões orientadas por dados. '
          }
        ]}
      />
      {/*
      <Product.CardSlider
        heading="Cases de sucesso"
        cards={[
          {
            to: 'https://www2.exactaworks.com.br/',
            text: 'Donec egestas diam molestie leo ultricies, vitae semper libero facilisis. Nullam metus.',
            date: 'Mar 28, 2024',
            image: Cover
          },
          {
            to: 'https://www2.exactaworks.com.br/',
            text: 'Donec egestas diam molestie leo ultricies, vitae semper libero facilisis. Nullam metus.',
            date: 'Mar 27, 2024',
            image: Cover
          },
          {
            to: 'https://www2.exactaworks.com.br/',
            text: 'Donec egestas diam molestie leo ultricies, vitae semper libero facilisis. Nullam metus.',
            date: 'Mar 26, 2024',
            image: Cover
          },
          {
            to: 'https://www2.exactaworks.com.br/',
            text: 'Donec egestas diam molestie leo ultricies, vitae semper libero facilisis. Nullam metus.',
            date: 'Mar 25, 2024',
            image: Cover
          },
          {
            to: 'https://www2.exactaworks.com.br/',
            text: 'Donec egestas diam molestie leo ultricies, vitae semper libero facilisis. Nullam metus.',
            date: 'Mar 24, 2024',
            image: Cover
          }
        ]}
      />

      <Product.Methodology /> */}

      <Product.SloganBenefits />

      <Product.BigNumbers
        items={[
          {
            label: 'mais rápido em tarefas repetitivas',
            value: '96%'
          },
          {
            label: 'maior confiança em qualidade de código',
            value: '85%'
          },
          {
            label: 'maior agilidade em code reviews',
            value: '15%'
          },
          {
            label: 'maior velocidade de maneira geral',
            value: '55%'
          }
        ]}
      />

      <Product.ExactaFlowTrigger />

      <Product.TriggerAction
        text={
          <>
            Vamos acelerar? <br /> Dê o start nos seus projetos digitais com
            <span className="emphasis">
              profissionais multidisciplinares certificados em IA
            </span>
            .
          </>
        }
        action={{
          label: 'Fale com a gente',
          href: '#contato'
        }}
      />

      <Product.Clients />
      <Product.Contact />

      <Posts tag="projetos-e-consultoria" />

      <Product.Newsletter />
    </Layout>
  );
}

export default ProjectAndConsultancy;
