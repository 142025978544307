import React from 'react';
import { HeadFC } from 'gatsby';

import Seo from '../../components/Seo';
import ProjectAndConsultancy from '../../components/pages/Solutions/ProjectAndConsultancy';

function ProjetosConsultoria() {
  return <ProjectAndConsultancy />;
}

export const Head: HeadFC = () => (
  <Seo
    title="Projetos e Consultoria"
    canonical="/solucoes/projetos-e-consultoria"
  />
);

export default ProjetosConsultoria;
